import React, { useState } from 'react';
import './App.css';

// Import images
import sourire1 from './images/sourire1.jpg';
import wine from './images/wine.jpg';
import surf from './images/surf.jpg';
import Attire1 from './images/Attire1.jpg';
import Attire2 from './images/Attire2.jpg';
import Attire3 from './images/Attire3.jpg';
import Attire4 from './images/Attire4.jpg';
import Attire5 from './images/Attire5.jpg';
import Attire6 from './images/Attire6.jpg';
import Plane from './images/Plane.jpg';
import Bus from './images/Bus.jpg';

function App() {
  const [language, setLanguage] = useState('en');

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  const getDaysUntilWedding = () => {
    const weddingDate = new Date('2024-12-21');
    const today = new Date();
    const timeDiff = weddingDate - today;
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  };

  const translations = {
    en: {
      title: "Paula Antonia Zamora Schiavetto & Jérémy Sauvageau",
      subtitle: "Together with their families,",
      inviteText: "Invite you to share with them as they join in marriage",
      dateLocation: "Saturday, December 21, 2024 • La Serena, Chile",
      countdown: `${getDaysUntilWedding()} days until the wedding!`,
      ourStoryTitle: "Our Story",
      eventDetailsTitle: "Event Details",
      discoverChileTitle: "Discover Chile",
      attireTitle: "Attire",
      rsvpTitle: "RSVP",
      giftListTitle: "Gift List",
      transportationTitle: "Transportation",
      ourStoryText: "From a chance encounter along the charming canals of Amsterdam to building a life together in Quebec, our love has grown across continents and cultures. It all began in February 2020 during a student exchange in Amsterdam. When the world changed, Jeremy returned to Canada, but in November 2020, as soon as the borders reopened, he flew to Chile and stayed until May 2021. In July 2021, Paula visited Canada for the first time. In July 2023, Mario, Ema, Ignacia, and Vittorio joined us for an unforgettable trip to Niagara Falls, where Jeremy asked Paula to marry him. Now, our greatest adventure awaits in December 2024, and we invite you to join us as we celebrate our love in La Serena, Chile, surrounded by family and friends.",
      attireText: "Formal attire. Dress to impress and make it an unforgettable celebration ! Please note that white and its tonal variations are reserved for the bride.",
      giftListText: "Your presence at our wedding is the greatest gift we could ever ask for. If you still wish to contribute, we have created a gift list as a way to share our future dreams and experiences with you. While the list may look like items to purchase, it’s actually a unique way for you to help us fund exciting activities we hope to do together in the future. However, you don’t necessarily need to go through the gift list website. We also welcome any other gifts you'd like to offer.",
      giftListLink: "View Gift List",
      rsvpText: "We can't wait to celebrate with you in La Serena!",
      rsvpLink: "https://docs.google.com/forms/d/e/1FAIpQLSeWYLe2F-dFwIKD8ZvujKThait4QeE4L6Jw5aR9TLEO82Ozhg/viewform?embedded=true",
      seeYouSoon: "See you at the end of the world!",
      transportationText: {
        airport: "Santiago International Airport, La Serena Airport",
        bus: "Bus service from Santiago to La Serena"
      },
      discoverChile: [
        { text: "Whether you're a first-time visitor or a seasoned traveler, Chile offers a wealth of unforgettable experiences:"},
        { text: "Wander through the colorful streets of Valparaíso, a UNESCO World Heritage site", link: "https://fr.tripadvisor.ca/Attractions-g294306-Activities-Valparaiso_Valparaiso_Region.html" },
        { text: "Savor world-class wines in the scenic Elqui Valley, home to Chile's famous pisco", link: "https://fr.tripadvisor.ca/Attraction_Review-g303674-d9705794-Reviews-Valle_del_Elqui-La_Serena_Coquimbo_Region.html" },
        { text: "Experience the magic of stargazing at renowned observatories under the clearest skies in the world", link: "https://fr.tripadvisor.ca/Attraction_Review-g970257-d3725244-Reviews-Observatorio_del_Pangue-Vicuna_Coquimbo_Region.html"},
        { text: "Bask in the golden glow of Pacific sunsets along Chile's coastline", link: "https://fr.tripadvisor.ca/Attraction_Review-g303674-d318267-Reviews-Avenida_del_Mar-La_Serena_Coquimbo_Region.html" },
        { text: "Explore the landscapes of San Pedro de Atacama, from salt flats to geysers", link:"https://fr.tripadvisor.ca/Attraction_Review-g303681-d315617-Reviews-Valle_De_La_Luna-San_Pedro_de_Atacama_Antofagasta_Region.html" },
        { text: "Journey to Patagonia to marvel at its majestic glaciers and rugged mountains", link:"https://fr.tripadvisor.ca/Attraction_Review-g670171-d314634-Reviews-Torres_del_Paine_National_Park-Torres_del_Paine_National_Park_Magallanes_Region.html" },
        { text: "Take a road trip along the breathtaking Carretera Austral, surrounded by pristine lakes and forests ", link:"https://www.chile.travel/en/where-to-go/destination/carretera-austral/" },
        { text: "Witness the sight of whales and dolphins in their natural habitat along Chile's coastline", link:"https://www.tripadvisor.com/Attraction_Review-g303674-d2372048-Reviews-or65-Isla_Damas-La_Serena_Coquimbo_Region.html"},
      ],
    },
    fr: {
      title: "Paula Antonia Zamora Schiavetto & Jérémy Sauvageau",
      subtitle: "Avec leurs familles,",
      inviteText: "Vous invitent à partager avec eux leur union",
      dateLocation: "Samedi 21 décembre 2024 • La Serena, Chili",
      countdown: `${getDaysUntilWedding()} jours avant le mariage !`,
      ourStoryTitle: "Notre Histoire",
      eventDetailsTitle: "Détails de l'Événement",
      discoverChileTitle: "Découvrez le Chili",
      attireTitle: "Tenue",
      rsvpTitle: "RSVP",
      giftListTitle: "Liste de Cadeaux",
      transportationTitle: "Transport",
      ourStoryText: "De la rencontre inattendue le long des charmants canaux d'Amsterdam à la construction d'une vie ensemble au Québec, notre amour a grandi à travers les continents et les cultures. Tout a commencé en février 2020, lors d'un échange étudiant à Amsterdam. Lorsque le monde a changé, Jeremy est rentré au Canada, mais en novembre 2020, dès que les frontières se sont rouvertes, il a pris un vol pour le Chili et y est resté jusqu'en mai 2021. En juillet 2021, Paula a visité le Canada pour la première fois. En juillet 2023, Mario, Ema, Ignacia et Vittorio se sont joints à nous pour un voyage inoubliable aux chutes du Niagara, où Jeremy a demandé à Paula de l'épouser. Maintenant, notre plus grande aventure nous attend en décembre 2024, et nous vous invitons à nous rejoindre pour célébrer notre amour à La Serena, au Chili, entourés de famille et d'amis.",
      attireText: "Tenue formelle. Habillez-vous pour impressionner et faire de cette célébration un moment inoubliable ! Veuillez noter que le blanc et ses variations tonales sont réservés à la mariée.",
      giftListText: "Votre présence est le plus beau cadeau que nous puissions demander. Si vous souhaitez nous offrir un cadeau, nous avons créé une liste de cadeaux pour partager avec vous nos futurs rêves et projets. Bien que cette liste puisse ressembler à des objets à acheter, elle est en réalité une façon unique de nous aider à financer des activités que nous espérons vivre ensemble dans le futur. Cependant, il n’est pas nécessaire de passer par le site de la liste de cadeaux. Nous serions également ravis de recevoir tout autre cadeau de votre part.",
      giftListLink: "Voir la liste de cadeaux",
      rsvpText: "Nous avons hâte de célébrer avec vous à La Serena!",
      rsvpLink: "https://docs.google.com/forms/d/e/1FAIpQLSc2Dm0Ho6L0M92BROZ34o42PtRmS7-2XjqsNSzv6lIDQ7cZ0w/viewform?embedded=true",
      seeYouSoon: "À bientôt au bout du monde !",
      transportationText: {
        airport: "Aéroport International de Santiago, Aéroport de La Serena",
        bus: "Service de bus de Santiago à La Serena"
      },
      discoverChile: [
        { text: "Que vous soyez un visiteur novice ou un voyageur chevronné, le Chili offre une multitude d'expériences inoubliables :"},
        { text: "Promenez-vous dans les rues colorées de Valparaíso, site du patrimoine mondial de l'UNESCO", link: "https://fr.tripadvisor.ca/Attractions-g294306-Activities-Valparaiso_Valparaiso_Region.html" },
        { text: "Savourez des vins de classe mondiale dans la pittoresque vallée de l'Elqui, berceau du fameux pisco chilien", link: "https://fr.tripadvisor.ca/Attraction_Review-g303674-d9705794-Reviews-Valle_del_Elqui-La_Serena_Coquimbo_Region.html" },
        { text: "Vivez la magie de l'observation des étoiles dans des observatoires renommés sous les ciels les plus clairs du monde", link: "https://fr.tripadvisor.ca/Attraction_Review-g970257-d3725244-Reviews-Observatorio_del_Pangue-Vicuna_Coquimbo_Region.html"},
        { text: "Profitez de la lueur dorée des couchers de soleil sur le Pacifique le long de la côte chilienne", link: "https://fr.tripadvisor.ca/Attraction_Review-g303674-d318267-Reviews-Avenida_del_Mar-La_Serena_Coquimbo_Region.html" },
        { text: "Explorez les paysages de San Pedro de Atacama, des salars aux geysers", link:"https://fr.tripadvisor.ca/Attraction_Review-g303681-d315617-Reviews-Valle_De_La_Luna-San_Pedro_de_Atacama_Antofagasta_Region.html" },
        { text: "Voyagez en Patagonie pour admirer ses glaciers majestueux et ses montagnes escarpées", link:"https://fr.tripadvisor.ca/Attraction_Review-g670171-d314634-Reviews-Torres_del_Paine_National_Park-Torres_del_Paine_National_Park_Magallanes_Region.html" },
        { text: "Faites un road trip le long de la spectaculaire Carretera Austral, entourée de lacs et de forêts vierges", link:"https://www.chile.travel/en/where-to-go/destination/carretera-austral/" },
        { text: "Observez les baleines et les dauphins dans leur habitat naturel le long de la côte chilienne", link:"https://www.tripadvisor.com/Attraction_Review-g303674-d2372048-Reviews-or65-Isla_Damas-La_Serena_Coquimbo_Region.html"},
      ],
    },
    es: {
      title: "Paula Antonia Zamora Schiavetto & Jérémy Sauvageau",
      subtitle: "Junto con sus familias,",
      inviteText: "Les invitan a compartir con ellos su unión en matrimonio",
      dateLocation: "Sábado 21 de diciembre de 2024 • La Serena, Chile",
      countdown: `¡${getDaysUntilWedding()} días para la boda!`,
      ourStoryTitle: "Nuestra Historia",
      eventDetailsTitle: "Detalles del Evento",
      discoverChileTitle: "Descubre Chile",
      attireTitle: "Vestimenta",
      rsvpTitle: "RSVP",
      giftListTitle: "Lista de Regalos",
      transportationTitle: "Transporte",
      ourStoryText: "Desde un encuentro inesperado a lo largo de los encantadores canales de Ámsterdam hasta construir una vida juntos en Quebec, nuestro amor ha crecido a través de continentes y culturas. Todo comenzó en febrero de 2020, durante un intercambio estudiantil en Ámsterdam. Cuando el mundo cambió, Jeremy regresó a Canadá, pero en noviembre de 2020, tan pronto como se abrieron las fronteras, voló a Chile y se quedó hasta mayo de 2021. En julio de 2021, Paula visitó Canadá por primera vez. En julio de 2023, Mario, Ema, Ignacia y Vittorio se unieron a nosotros para un viaje inolvidable a las cataratas del Niágara, donde Jeremy le pidió a Paula que se casara con él. Ahora, nuestra mayor aventura nos espera en diciembre de 2024, y los invitamos a unirse a nosotros para celebrar nuestro amor en La Serena, Chile, rodeados de familia y amigos.",
      attireText: "Vestimenta formal. Vístete para impresionar y hacer de esta celebración un momento inolvidable. Ten en cuenta que el blanco y sus variaciones tonales están reservados para la novia.",
      giftListText: "Su presencia en nuestro matrimonio es el mejor regalo que podríamos pedir. Si aún así desean contribuir, hemos creado una lista de regalos como una forma de compartir nuestros sueños y proyectos futuros con ustedes. Aunque la lista pueda parecer de objetos a comprar, es en realidad una manera única de ayudarnos a financiar actividades que esperamos disfrutar juntos en el futuro. Sin embargo, no es necesario utilizar el sitio web de la lista de regalos. También agradecemos cualquier otro obsequio que deseen ofrecer.",
      giftListLink: "Ver lista de regalos",
      rsvpText: "¡Esperamos celebrar con ustedes en La Serena!",
      rsvpLink: "https://docs.google.com/forms/d/e/1FAIpQLSdPSGolV_ITn71AKUkLrkYDT-KqO_x6_Bvrlxwqx0c5ZDi50g/viewform?embedded=true",
      seeYouSoon: "¡Nos vemos en el fin del mundo!",
      transportationText: {
        airport: "Aeropuerto Internacional de Santiago, Aeropuerto de La Serena",
        bus: "Servicio de autobús de Santiago a La Serena"
      },
      discoverChile: [
        { text: "Ya sea que seas un visitante primerizo o un viajero experimentado, Chile ofrece una gran cantidad de experiencias inolvidables:"},
        { text: "Pasea por las coloridas calles de Valparaíso, un sitio del Patrimonio Mundial de la UNESCO", link: "https://fr.tripadvisor.ca/Attractions-g294306-Activities-Valparaiso_Valparaiso_Region.html" },
        { text: "Saborea vinos de clase mundial en el pintoresco Valle del Elqui, hogar del famoso pisco chileno", link: "https://fr.tripadvisor.ca/Attraction_Review-g303674-d9705794-Reviews-Valle_del_Elqui-La_Serena_Coquimbo_Region.html" },
        { text: "Experimenta la magia de la observación de estrellas en reconocidos observatorios bajo los cielos más claros del mundo", link: "https://fr.tripadvisor.ca/Attraction_Review-g970257-d3725244-Reviews-Observatorio_del_Pangue-Vicuna_Coquimbo_Region.html"},
        { text: "Disfruta del resplandor dorado de las puestas de sol del Pacífico a lo largo de la costa chilena", link: "https://fr.tripadvisor.ca/Attraction_Review-g303674-d318267-Reviews-Avenida_del_Mar-La_Serena_Coquimbo_Region.html" },
        { text: "Explora los paisajes de San Pedro de Atacama, desde salares hasta géiseres", link:"https://fr.tripadvisor.ca/Attraction_Review-g303681-d315617-Reviews-Valle_De_La_Luna-San_Pedro_de_Atacama_Antofagasta_Region.html" },
        { text: "Viaja a la Patagonia para maravillarte con sus majestuosos glaciares y montañas escarpadas", link:"https://fr.tripadvisor.ca/Attraction_Review-g670171-d314634-Reviews-Torres_del_Paine_National_Park-Torres_del_Paine_National_Park_Magallanes_Region.html" },
        { text: "Realiza un viaje por carretera a lo largo de la impresionante Carretera Austral, rodeada de lagos y bosques vírgenes", link:"https://www.chile.travel/en/where-to-go/destination/carretera-austral/" },
        { text: "Observa ballenas y delfines en su hábitat natural a lo largo de la costa chilena", link:"https://www.tripadvisor.com/Attraction_Review-g303674-d2372048-Reviews-or65-Isla_Damas-La_Serena_Coquimbo_Region.html"},
      ],
    },
    it: {
      title: "Paula Antonia Zamora Schiavetto & Jérémy Sauvageau",
      subtitle: "Insieme alle loro famiglie,",
      inviteText: "Vi invitano a condividere con loro la loro unione in matrimonio",
      dateLocation: "Sabato 21 dicembre 2024 • La Serena, Cile",
      countdown: `${getDaysUntilWedding()} giorni al matrimonio!`,
      ourStoryTitle: "La Nostra Storia",
      eventDetailsTitle: "Dettagli dell'Evento",
      discoverChileTitle: "Scopri il Cile",
      attireTitle: "Abbigliamento",
      rsvpTitle: "RSVP",
      giftListTitle: "Lista dei Regali",
      transportationTitle: "Trasporto",
      ourStoryText: "Da un incontro inaspettato lungo i suggestivi canali di Amsterdam alla costruzione di una vita insieme in Quebec, il nostro amore è cresciuto attraverso continenti e culture. Tutto è cominciato nel febbraio 2020, durante uno scambio studentesco ad Amsterdam. Quando il mondo è cambiato, Jeremy è tornato in Canada, ma a novembre 2020, non appena le frontiere si sono riaperte, è volato in Cile e vi è rimasto fino a maggio 2021. Nel luglio 2021, Paula ha visitato il Canada per la prima volta. Nel luglio 2023, Mario, Ema, Ignacia e Vittorio si sono uniti a noi per un viaggio indimenticabile alle cascate del Niagara, dove Jeremy ha chiesto a Paula di sposarlo. Ora, la nostra più grande avventura ci attende a dicembre 2024, e vi invitiamo a unirvi a noi per celebrare il nostro amore a La Serena, in Cile, circondati da famiglia e amici.",
      attireText: "Abbigliamento formale. Vestitevi per fare colpo e rendere questa celebrazione un momento indimenticabile! Si prega di notare che il bianco e le sue variazioni tonali sono riservate alla sposa.",
      giftListText: "La vostra presenza al nostro matrimonio è il regalo più grande che potremmo desiderare. Se volete comunque farci un dono, abbiamo creato una lista come modo per condividere con voi i nostri sogni e progetti futuri. Anche se la lista può sembrare una serie di oggetti da acquistare, in realtà è un modo originale per aiutarci a finanziare attività che speriamo di vivere insieme in futuro. Tuttavia, non è necessario utilizzare il sito della lista di nozze. Accettiamo anche con piacere qualsiasi altro tipo di regalo.",
      giftListLink: "Visualizza lista regali",
      rsvpText: "Non vediamo l'ora di festeggiare con voi a La Serena!",
      rsvpLink: "https://docs.google.com/forms/d/e/1FAIpQLSfVEs_eKRhyRmeTyUP5LbgJZeQ8iWL3nyRhzpqnw3YwqZRYRQ/viewform?embedded=true",
      seeYouSoon: "Ci vediamo alla fine del mondo!",
      transportationText: {
        airport: "Aeroporto Internazionale di Santiago, Aeroporto di La Serena",
        bus: "Servizio autobus da Santiago a La Serena"
      },
      discoverChile: [
        { text: "Che siate visitatori alle prime armi o viaggiatori esperti, il Cile offre una ricchezza di esperienze indimenticabili:"},
        { text: "Passeggia per le strade colorate di Valparaíso, sito Patrimonio dell'Umanità dell'UNESCO", link: "https://fr.tripadvisor.ca/Attractions-g294306-Activities-Valparaiso_Valparaiso_Region.html" },
        { text: "Assapora vini di classe mondiale nella pittoresca Valle del Elqui, patria del famoso pisco cileno", link: "https://fr.tripadvisor.ca/Attraction_Review-g303674-d9705794-Reviews-Valle_del_Elqui-La_Serena_Coquimbo_Region.html" },
        { text: "Vivi la magia dell'osservazione delle stelle in rinomati osservatori sotto i cieli più limpidi del mondo", link: "https://fr.tripadvisor.ca/Attraction_Review-g970257-d3725244-Reviews-Observatorio_del_Pangue-Vicuna_Coquimbo_Region.html"},
        { text: "Goditi il bagliore dorato dei tramonti sul Pacifico lungo la costa cilena", link: "https://fr.tripadvisor.ca/Attraction_Review-g303674-d318267-Reviews-Avenida_del_Mar-La_Serena_Coquimbo_Region.html" },
        { text: "Esplora i paesaggi di San Pedro de Atacama, dalle saline ai geyser", link:"https://fr.tripadvisor.ca/Attraction_Review-g303681-d315617-Reviews-Valle_De_La_Luna-San_Pedro_de_Atacama_Antofagasta_Region.html" },
        { text: "Viaggia in Patagonia per meravigliarti dei suoi maestosi ghiacciai e delle sue montagne aspre", link:"https://fr.tripadvisor.ca/Attraction_Review-g670171-d314634-Reviews-Torres_del_Paine_National_Park-Torres_del_Paine_National_Park_Magallanes_Region.html" },
        { text: "Fai un viaggio in auto lungo la mozzafiato Carretera Austral, circondato da laghi e foreste incontaminate", link:"https://www.chile.travel/en/where-to-go/destination/carretera-austral/" },
        { text: "Osserva balene e delfini nel loro habitat naturale lungo la costa cilena", link:"https://www.tripadvisor.com/Attraction_Review-g303674-d2372048-Reviews-or65-Isla_Damas-La_Serena_Coquimbo_Region.html"},
      ],
    }
  };

  return (
    <div className="App">
      <div className="language-selector">
        <button onClick={() => handleLanguageChange('en')}>English</button>
        <button onClick={() => handleLanguageChange('fr')}>Français</button>
        <button onClick={() => handleLanguageChange('es')}>Español</button>
        <button onClick={() => handleLanguageChange('it')}>Italiano</button>
      </div>

      <div className="header">
        <p>{translations[language].subtitle}</p>
        <h1>{translations[language].title}</h1>
        <p>{translations[language].inviteText}</p>
        <p>{translations[language].dateLocation}</p>
        <p>{translations[language].countdown}</p>
      </div>

      <div className="our-story">
        <h2>{translations[language].ourStoryTitle}</h2>
        <img src={sourire1} alt="Our Story" className="story-image" />
        <p>{translations[language].ourStoryText}</p>
      </div>

      <div className="event-details">
        <h2>{translations[language].eventDetailsTitle}</h2>
        <p><strong>Venue Villa Verla</strong><br />Panamericana Norte 807<br />La Serena, Coquimbo, Chile</p>
        <p><a href="https://maps.app.goo.gl/aqmPdNVvZSavWmkd8" target="_blank" rel="noopener noreferrer" className="blue-link">View on Google Maps</a></p>
        <p><strong>Schedule</strong><br />Arrival: 17:30<br />Ceremony: 18:00</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/autHV0zikh8?si=K4Z6ZPXAsDUM9ldT" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      </div>

      <div className="discover-chile">
        <h2>{translations[language].discoverChileTitle}</h2>
        <ul className="no-bullets">
          {translations[language].discoverChile.map((item, index) => (
            <li key={index}>
              {item.link ? <a href={item.link} target="_blank" rel="noopener noreferrer">{item.text}</a> : item.text}
            </li>
          ))}
        </ul>
        <div className="images">
          <img src={wine} alt="wine" className="chile-image" />
          <img src={surf} alt="Surf" className="chile-image" />
        </div>
      </div>

      <div className="attire">
        <h2>{translations[language].attireTitle}</h2>
        <p>{translations[language].attireText}</p>
        <div className="attire-images">
          <img src={Attire1} alt="Attire 1" className="attire-image" />
          <img src={Attire2} alt="Attire 2" className="attire-image" />
          <img src={Attire3} alt="Attire 3" className="attire-image" />
          <img src={Attire4} alt="Attire 4" className="attire-image" />
          <img src={Attire5} alt="Attire 5" className="attire-image" />
          <img src={Attire6} alt="Attire 6" className="attire-image" />
        </div>
      </div>

      <div className="rsvp">
        <h2>{translations[language].rsvpTitle}</h2>
        <p>{translations[language].rsvpText}</p>
        <iframe src={translations[language].rsvpLink} title="RSVP" width="640" height="1013" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
      </div>

      <div className="gift-list">
        <h2>{translations[language].giftListTitle}</h2>
        <p>{translations[language].giftListText}</p>
        <a href="https://milistadenovios.cl/lista/paulajeremy" target="_blank" rel="noopener noreferrer" className="blue-link">
          {translations[language].giftListLink}
        </a>
      </div>

      <div className="transportation">
        <h2>{translations[language].transportationTitle}</h2>
        <p><strong>Airport:</strong> {translations[language].transportationText.airport}</p>
        <p><strong>Bus:</strong> {translations[language].transportationText.bus}</p>
        <div className="transportation-images">
          <img src={Plane} alt="Plane" className="transportation-image" />
          <img src={Bus} alt="Bus" className="transportation-image" />
        </div>
      </div>
      <footer className="footer">
        <p>{translations[language].seeYouSoon}</p>
      </footer>
    </div>
  );
}

export default App;
